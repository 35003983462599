// ParticleField.js
import React, { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as THREE from 'three';

const ParticleField = () => {
    const points = useRef();

    const particles = useMemo(() => {
        const positions = [];
        for (let i = 0; i < 5000; i++) {
            positions.push(
                (Math.random() - 0.5) * 20,
                (Math.random() - 0.5) * 20,
                (Math.random() - 0.5) * 20
            );
        }
        return new Float32Array(positions);
    }, []);

    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();
        points.current.rotation.y = elapsedTime * 0.02;
    });

    return (
        <Points ref={points}>
            <bufferGeometry attach="geometry">
                <bufferAttribute
                    attach="attributes-position"
                    array={particles}
                    count={particles.length / 3}
                    itemSize={3}
                />
            </bufferGeometry>
            <PointMaterial
                transparent
                color="#00FFFF"
                size={0.05}
                sizeAttenuation
                depthWrite={false}
            />
        </Points>
    );
};

export default ParticleField;
